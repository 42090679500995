import type { YmdRoute } from '@router/types';
import { onboardingRouteDefaultConfig } from '../../router';

export default <Record<string, YmdRoute>>{
	'/': {
		...onboardingRouteDefaultConfig,
		as: 'home',
		module: () => import('./'),
	},
};
