import ModelClass from '../Model.js';

class ModelOnboarding extends ModelClass {
	apiHost = `${import.meta.env.VITE_API_HOST}onboard/`;
	onAPIworkAcceptForceError(error, path, response) {
		this.resendAfterForceDialog(error, path, response);
	}
}

const Model = new ModelOnboarding();
export { Model };
