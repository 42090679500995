import YmmdRouter from '@router';
import { User } from './User';
import { Log } from '@modules/Log';
import type { YmdRoute } from '@router/types';
import { matomoTracker } from '@modules/matomoTracker';
import { socket } from '@modules/Socket';

export const Router = new YmmdRouter(import.meta.env.BASE_URL);

Router.notFound(() => {
	const redirectTo = User.hasSession ? '/notfound' : '/login';
	Router.navigate(redirectTo, {
		updateBrowserURL: false,
	});
	if (User.hasSession) {
		const { url } = Router.getCurrentLocation();
		Log.error(new Error(`Not found: ${url}`));
	}
});

const sessionOk = (ymdRoute: YmdRoute) => {
	if (ymdRoute.isAuthRequired && !User.hasSession && ymdRoute.as !== 'login') {
		Router.navigate('/login', {
			updateBrowserURL: false,
		});
		return false;
	}

	return true;
};

export const onboardingRouteDefaultConfig: Omit<YmdRoute, 'as'> = {
	hooks: {
		before: (canContinueNavigation, match, ymdRoute) => {
			canContinueNavigation(sessionOk(ymdRoute, match));
		},
		after: (match, ymdRoute) => {
			if (ymdRoute.hasPageTracking) matomoTracker.pageTrack();
			socket.initAndAuthorize(
				User.user?.websocket,
				import.meta.env.VITE_YOUMMDAY_SOCKET,
			);
		},
	},
};
