import type { YmdRoute } from '@router/types';
import { onboardingRouteDefaultConfig } from '../../router';

export default <Record<string, YmdRoute>>{
	'/talent-security/:talentId': {
		...onboardingRouteDefaultConfig,
		as: 'security',
		module: () => import('./'),
	},
};
