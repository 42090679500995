import type { YmdRoute } from '@router/types';
import { onboardingRouteDefaultConfig } from '../router';

export default <Record<string, YmdRoute>>{
	'/talent/:talentId/:settingsgroup': {
		...onboardingRouteDefaultConfig,
		as: 'talent',
		module: () => import('./talent.js'),
	},
	'/talent/:talentId': {
		...onboardingRouteDefaultConfig,
		as: 'talent',
		module: () => import('./talent.js'),
	},
	'/webcamsessions': {
		...onboardingRouteDefaultConfig,
		as: 'webcamsessions',
		module: () => import('./webcamsessions.js'),
	},
	'/verification': {
		...onboardingRouteDefaultConfig,
		as: 'verification',
		module: () => import('./verification.js'),
	},
	'/schedule': {
		...onboardingRouteDefaultConfig,
		as: 'schedule',
		module: () => import('./schedule.js'),
	},
	'/supporttickets': {
		...onboardingRouteDefaultConfig,
		as: 'supporttickets',
		module: () => import('./supporttickets.js'),
	},
};
