import { BaseUser } from '../User';

class OnboardingUser extends BaseUser {
	get spokenLangs(): string[][] {
		return (
			this.user.spokenLangs
				?.sort()
				.map((l) => l.split(':'))
				.filter((l) => !/\d/u.test(l[0])) || []
		);
	}
}

const User = new OnboardingUser();

export { User };
