import type { YmdRoute } from '@router/types';
import { onboardingRouteDefaultConfig } from '../../router';

export default <Record<string, YmdRoute>>{
	'/assetmanager': {
		...onboardingRouteDefaultConfig,
		as: 'assetmanager',
		module: () => import('./'),
	},
};
