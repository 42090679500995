import AppBase from '@components/app-base/';
import { header } from './header.js';
import { aside } from './aside.js';
import { customElement, query, state } from 'lit/decorators.js';
import { render, html, nothing } from 'lit';
import { when } from 'lit/directives/when.js';
import { audioControls } from '../AudioPlayer.js';
import { User } from './User';
import { classMap } from 'lit/directives/class-map.js';
import { socket, type PushMsg } from '@modules/Socket';
import { createNavigateEvent } from '@components/app-base/custom-events';
import { i18n } from '@i18n';
import Template from '../Template.js';
import '@utils/formSerializeObject.js';
import { Storage } from '@modules/Storage';
import { notification, br } from '@utils';
import { Log } from '@modules/Log.js';
import { API } from './api';
import { Router } from './router';
import type { PageBase } from '@components/page-base';
import type YmmdRouter from '@router';

@customElement('app-onboarding')
export default class AppOnboarding extends AppBase {
	view?: PageBase | Template;
	// deprecated: legacy container is for Template Class Instance
	@query('#legacy-page') legacyContainer!: HTMLElement;

	@state() route?: YmmdRouter['ymdRoutes']['value'];

	localStorage = new Storage(this.nameSpace, 'local');

	connectedCallback() {
		this.addEventListeners();
		super.connectedCallback();
		Router.subscribe(async (route, params, queryParams) => {
			if (!route.routeDefaultExport) return;
			if (this.view instanceof Template) this.view.destroy();
			if (route.routeDefaultExport.isLegacy) {
				this.view = new route.routeDefaultExport(
					// eslint-disable-next-line no-undefined
					undefined,
					params || {},
					queryParams || {},
					route,
				) as Template;
				if (!this.view) return;

				render(nothing, this);
				render(this.view.container, this.legacyContainer);
				await this.view.init();
			} else {
				this.view = new route.routeDefaultExport();
				if (!this.view) return;
				this.view.params = params || {};
				this.view.query = queryParams || {};
				this.view.route = route;
				render(nothing, this.legacyContainer);
				render(this.view, this);
			}
			this.route = route;
		});
	}

	addEventListeners() {
		socket.Log = Log;
		socket.subscribe(this, 'push', ({ detail: { msgs = [] } }) => {
			if (
				!msgs.some(
					({ answer, id }) =>
						!answer && // not answered yet
						!(this.localStorage.get('seenPushes') || []).includes(id), // not yet seen
				)
			) {
				return;
			}
			const singlePush = (push: PushMsg) => {
				const container = document.createElement('div');
				render(
					html`
						<div style="display:flex; align-items:center;">
							<div>
								<user-thumb
									.user=${push.user || { ymdItself: true }}
									size="40"
								></user-thumb>
							</div>
							<div>
								<h5 class="m-0">${push.title}</h5>
							</div>
						</div>
						<p>${br(push.text)}</p>
					`,
					container,
				);
				return container;
			};
			notification(window.T.message.event.pushmsg, '', i18n.language);
			msgs.forEach((push) => {
				const container = singlePush(push);
				this.onShowToast(
					new CustomEvent('showToast', {
						detail: {
							element: container,
							variant: 'neutral',
							onDismissed: () => {
								this.localStorage.push('seenPushes', push.id);
							},
						},
					}),
				);
			});
		});
	}

	onLogout({ detail: onPurpose }: CustomEvent<boolean>) {
		delete User.user;
		Router.navigate('/login', {
			updateBrowserURL: false,
		});
		socket.kill();
		sessionStorage.clear();
		if (!onPurpose) {
			this.showSnackBar({
				str: window.T.alert.error.auth,
				variant: 'danger',
			});
		} else {
			API.POST('/logout');
		}
	}

	onNavigate(e: ReturnType<typeof createNavigateEvent>) {
		Router.navigate(e.detail);
	}
	// just a bridge for legacy Model-Class
	userUpdated(user: APIuser) {
		User.updateUser(user);
	}

	render() {
		return html`
			<article class=${classMap({ 'has-header': !!this.route?.hasHeader })}>
				${when(
					User.hasSession && this.route?.hasAsideNavigation,
					() => html`
						<aside>${aside()}</aside>
					`,
				)}

				<main>
					${audioControls}
					<section
						class=${classMap({
							'no-padding':
								!this.route?.hasHeader && !this.route?.hasAsideNavigation,
						})}
					>
						${when(
							this.isLoading,
							() => html`
								<div class="loading-bar"></div>
							`,
						)}
						<slot></slot>
						<div id="legacy-page"></div>
					</section>
				</main>
			</article>

			${when(
				this.route?.hasHeader && User.hasSession,
				() => html`
					<header>${header()}</header>
				`,
			)}
			${when(this.dialog, () => this.renderDialog())}
			<!---->
			${this.renderSnackBar()}
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'app-onboarding': AppOnboarding;
	}
}
